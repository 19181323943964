var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { checkDevice, ttfb } from "@pernod-ricard-global-cms/jsutils";
import lazyloader from "@pernod-ricard-global-cms/cbllazyloader";
import jsAssets from "Assets/js/modules/jsAssets";
import reservebarPopup from "Assets/js/modules/library-modules/reservebar-popup/reservebar-popup";
lazyloader.options.debugLogMessages = false;
lazyloader.options.rootMargin = "100% 0px 300px 0px";
lazyloader.options.assetArray = jsAssets.dynamicAssets;
let runLazyLoad = () => {
    lazyloader.lazyloaderInit();
};
function yieldToMain() {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}
window.addEventListener("DOMContentLoaded", () => {
    function runSiteJs() {
        return __awaiter(this, void 0, void 0, function* () {
            // Create an array of functions to run:
            const tasks = [ttfb, checkDevice, runLazyLoad, reservebarPopup];
            // Loop over the tasks:
            while (tasks.length > 0) {
                // Shift the first task off the tasks array:
                const task = tasks.shift();
                // Run the task:
                task();
                // Yield to the main thread:
                yield yieldToMain();
            }
        });
    }
    runSiteJs();
});
